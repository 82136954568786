import NumberFormat from "react-number-format";

// Input component which shows the value in euro format
const InputFormat = ({value, onChange}) => {
    return <NumberFormat className={"infix"}
                         value={value}
                         onValueChange={e => onChange(e.floatValue)}
                         displayType={'number'}
                         thousandSeparator={true}
                         allowNegative={false}
                         prefix={'€'}


    />
}
export default InputFormat