import React, {useEffect, useState} from "react";

import {Button, Card, CardBody, CardHeader, Col, Input, Row, Table} from "reactstrap";
import NumberFormat from "react-number-format";
import Select from "react-select";
import Facturatie from "./Facturatie";
import {Checkbox} from "@material-ui/core";
import projectApi from "../api/project";
import {PDFDownloadLink} from '@react-pdf/renderer';
import {MyDocument} from "./Print";
import {Spinner} from "react-bootstrap";
import Loader from "./Loader";
import EditFinanceModal from "./EditFinanceModal";
import notyfApi from "../utility/notyfApi";
import InputFormat from "./customComponents/InputFormat";


// Euro formatter
const euroFormat = (value) => {
    return Intl.NumberFormat('nl-NL', {
        style: 'currency',
        currency: 'EUR'
    }).format(value)
}

// Default taxation options
const tax_options = [
    {value: 'BTW21%', label: 'BTW21%'},
    {value: 'BTWverlegd(0%)', label: 'BTWverlegd(0%)'},
]


let pms_options = [];
let sls_options = []
let acs_options = []

// Main component which handles all the calculations
const ProjectForm = ({project}) => {
    const [discount, setDiscount] = useState(parseFloat(project.project_form_field_equipment_discount)) // Amount of discount
    const [risk, setRisk] = useState(parseFloat(project.project_form_risc_percentage)) // Risk amount
    const [service, setService] = useState(project.project_form_service_assignment) // Boolean indicates if it is a service project
    const [saveLoading, setSaveLoading] = useState(false) // If the project is saving
    const [loading, setLoading] = useState(true) // If the project is loading

    const [costs, setCosts] = useState({
        Regelapparatuur: parseFloat(project.project_form_field_equipment_cost_price) || null,
        NetwerkControllers: parseFloat(project.project_form_network_equipment_cost_price) || null,
        Naregelingen: parseFloat(project.project_form_nar_equipment_cost_price) || null,
        NettoApparatuur: parseFloat(project.project_form_netto_equipment_cost_price) || null,
        EngineerProjectBegeleiding: null,
        Inregelen: null,
        Schakelkasten: parseFloat(project.project_form_eb_cost_price) || null,
        Tekenwerk: null,
        Installatie: parseFloat(project.project_form_installation_cost_price) || null,
        WerkzaamhedenXconnect: null,
        NettoverkoopprijsDiversen: parseFloat(project.project_form_open_category_cost_price) || null,
        Bekabeling: parseFloat(project.project_form_cabling_cost_price) || null,
    }) // The cost column values

    const [prices, setPrices] = useState({
        Regelapparatuur: parseFloat(project.project_form_field_equipment_list_price) || null,
        NetwerkControllers: parseFloat(project.project_form_network_equipment_list_price) || null,
        Naregelingen: parseFloat(project.project_form_nar_equipment_list_price) || null,
        NettoApparatuur: parseFloat(project.project_form_netto_equipment_list_price) || null,
        EngineerProjectBegeleiding: parseFloat(project.project_form_eng_pl_list_price) || null,
        Inregelen: parseFloat(project.project_form_ibs_list_price) || null,
        Schakelkasten: parseFloat(project.project_form_eb_list_price) || null,
        Tekenwerk: parseFloat(project.project_form_tek_list_price) || null,
        Installatie: parseFloat(project.project_form_installation_list_price) || null,
        WerkzaamhedenXconnect: parseFloat(project.project_form_subcontractor_list_price) || null,
        NettoverkoopprijsDiversen: parseFloat(project.project_form_open_category_list_price) || null,
        Bekabeling: parseFloat(project.project_form_cabling_list_price) || null,
    })  // The price ("verkoop") column values

    const [margins, setMargins] = useState({
        Regelapparatuur: 1 - (costs.Regelapparatuur / prices.Regelapparatuur) || null,
        NetwerkControllers: 1 - (costs.NetwerkControllers / prices.NetwerkControllers) || null,
        Naregelingen: 1 - (costs.Naregelingen / prices.Naregelingen) || null,
        NettoApparatuur: 1 - (costs.NettoApparatuur / prices.NettoApparatuur) || null,
        EngineerProjectBegeleiding: 1 - (costs.EngineerProjectBegeleiding / prices.EngineerProjectBegeleiding) || null,
        Inregelen: 1 - (costs.Inregelen / prices.Inregelen) || null,
        Schakelkasten: 1 - (costs.Schakelkasten / prices.Schakelkasten) || null,
        Tekenwerk: 1 - (costs.Tekenwerk / prices.Tekenwerk) || null,
        Installatie: 1 - (costs.Installatie / prices.Installatie) || null,
        WerkzaamhedenXconnect: 1 - (costs.WerkzaamhedenXconnect / prices.WerkzaamhedenXconnect) || null,
        NettoverkoopprijsDiversen: 1 - (costs.NettoverkoopprijsDiversen / prices.NettoverkoopprijsDiversen) || null,
        Bekabeling: 1 - (costs.Bekabeling / prices.Bekabeling) || null,
    }) // The margin values

    const [sums, setSums] = useState({
        costs: (Object.values(costs).reduce((a, b) => a + b) * (1 + (risk / 100)) || 0),
        prices: ((Object.values(prices).slice(1).reduce((a, b) => a + b) || 0) + (prices.Regelapparatuur * (1 - (discount / 100))) || 0) || 0
    }) // The sum of values

    const [hours, setHours] = useState({
        TEK: parseFloat(project.project_form_tek_hours) || null,
        ENG: parseFloat(project.project_form_eng_hours) || null,
        PL: parseFloat(project.project_form_pl_hours) || null,
        WERKVB: parseFloat(project.project_form_werkvb_hours) || null,
        IBS: parseFloat(project.project_form_ibs_hours) || null,
        XCONNECT: parseFloat(project.project_form_subc_hours) || null,
    }) // The hours (Arbeid)

    const [rates] = useState({
        TEK: parseFloat(project.project_form_hourly_rate_cost_eng_tek_wvb),
        ENG: parseFloat(project.project_form_hourly_rate_cost_eng_tek_wvb),
        PL: parseFloat(project.project_form_hourly_rate_cost_pl),
        WERKVB: parseFloat(project.project_form_hourly_rate_cost_eng_tek_wvb),
        IBS: parseFloat(project.project_form_hourly_rate_cost_ibs),
        XCONNECT: parseFloat(project.project_form_hourly_rate_cost_subcontractor),
    }) // Uurtarieven kost

    const [ratesSell] = useState({
        TEK: parseFloat(project.project_form_hourly_rate_list_eng_tek_wvb),
        ENG: parseFloat(project.project_form_hourly_rate_list_eng_tek_wvb),
        PL: parseFloat(project.project_form_hourly_rate_list_pl),
        WERKVB: parseFloat(project.project_form_hourly_rate_list_eng_tek_wvb),
        IBS: parseFloat(project.project_form_hourly_rate_list_ibs),
        XCONNECT: parseFloat(project.project_form_hourly_rate_list_subcontractor),
    }) // Uurtarieven verkoop

    const [projectInfo, setProjectInfo] = useState({
        Verkoper: project.project_form_sales_name,
        Projectnaam: project.project_form_name,
        Projectnummer: project.project_form_code,
        Gebouwnaam: project.project_form_service_project,
        Plaatsnaam: project.project_form_service_project_city,
        Projectleider: project.project_form_project_manager,
        Betalingstermijn: project.project_form_term_of_payment,
        BTW: project.project_form_vat,
        Totaal: sums.prices,
        Opdrachtdatum: project.project_form_assignment_date,
        Ordernummerklant: project.project_form_assignment_order_nr,
        Opdrachtgever: project.project_form_assignment_client,
        OpdrachtgeverAfdeling: project.project_form_assignment_client_department,
        Adres: project.project_form_address,
        Postcode: project.project_form_country,
        Contactpersoon: project.project_form_contact_name,
        AantalHangkasten: project.project_form_amount_eb_sm,
        AantalStaandekasten: project.project_form_amount_eb_lg,
        email: project.project_form_contact_email
    }) // Formulier variables

    const [facturatieList, setFacturatieList] = useState([]) // Facturatie array

    // Get data from the server
    useEffect(() => {
        // Dit niet weghalen geeft problemen als array niet leeg is
        pms_options = []
        sls_options = []
        acs_options = []

        async function fetchData() {
            const resSales = await projectApi.getEmployeesSales()
            if (!resSales.ok) return notyfApi.error("Kan werknemers niet ophalen")

            for (let x of resSales.data.users) {
                sls_options.push({value: x.name, label: x.name})
            }

            const resProjects = await projectApi.getEmployeesProjects()
            if (!resProjects.ok) return notyfApi.error("Kan werknemers niet ophalen")
            for (let x of resProjects.data.users) {
                pms_options.push({value: x.name, label: x.name})
            }

            const resAccounts = await projectApi.getOfferAccounts(project.project_form_project_id)
            if (!resAccounts.ok) return notyfApi.error("Kan aanvragers niet ophalen")

            for (let x of resAccounts.data) {
                acs_options.push({
                    value: x,
                    label: `${x.sales_contact_firstname || ""} ${x.sales_contact_surname_prefix ? x.sales_contact_surname_prefix + " " : ""} ${x.sales_contact_lastname || ""} ${x.sales_account_name}`
                })
            }

            const resOffer = await projectApi.getOfferInfo(project.project_form_project_id)
            if (!resProjects.ok) return notyfApi.error("Kan offerte niet ophalen")
            setProjectInfo({
                ...projectInfo,
                Gebouwnaam: resOffer.data.service_project_name,
                Plaatsnaam: `${resOffer.data.sales_offer_offer_street || ""} ${resOffer.data.sales_offer_offer_street_nr || ""} ${resOffer.data.sales_offer_city || ""}`
            })
            setLoading(false)
        }

        fetchData();

    }, []);

    // Calculate the margins anytime the costs, prices, risk or discount has been changed
    useEffect(() => {
        setMargins({
            Regelapparatuur: (1 - (costs.Regelapparatuur / (prices.Regelapparatuur * (1 - (discount / 100))))) || null,
            NetwerkControllers: 1 - (costs.NetwerkControllers / prices.NetwerkControllers) || null,
            Naregelingen: 1 - (costs.Naregelingen / prices.Naregelingen) || null,
            NettoApparatuur: 1 - (costs.NettoApparatuur / prices.NettoApparatuur) || null,
            EngineerProjectBegeleiding: 1 - (costs.EngineerProjectBegeleiding / prices.EngineerProjectBegeleiding) || null,
            Inregelen: 1 - (costs.Inregelen / prices.Inregelen) || null,
            Schakelkasten: 1 - (costs.Schakelkasten / prices.Schakelkasten) || null,
            Tekenwerk: 1 - (costs.Tekenwerk / prices.Tekenwerk) || null,
            Installatie: 1 - (costs.Installatie / prices.Installatie) || null,
            WerkzaamhedenXconnect: 1 - (costs.WerkzaamhedenXconnect / prices.WerkzaamhedenXconnect) || null,
            NettoverkoopprijsDiversen: 1 - (costs.NettoverkoopprijsDiversen / prices.NettoverkoopprijsDiversen) || null,
            Bekabeling: 1 - (costs.Bekabeling / prices.Bekabeling) || null,
        })

        if ((Object.values(prices).slice(1).reduce((a, b) => a + (b || 0)) + ((prices.Regelapparatuur * (1 - (discount / 100))) || 0)) >= 250) {
            setSums({
                costs: Object.values(costs).reduce((a, b) => (a || 0) + (b || 0)) * ((1 + (risk / 100)) || 0),
                prices: (Object.values(prices).slice(1).reduce((a, b) => a + (b || 0)) + ((prices.Regelapparatuur * (1 - (discount / 100))) || 0))
            })
        } else {
            setSums({
                costs: (Object.values(costs).reduce((a, b) => a + (b || 0)) * (1 + (risk / 100)) || 0),
                prices: 250
            })
        }
    }, [costs, prices, risk, discount])

    // When hours or service is changed update the costs and prices
    useEffect(() => {
        setCosts({
            ...costs,
            EngineerProjectBegeleiding: hours.ENG * rates.ENG + hours.PL * rates.PL + hours.WERKVB * rates.WERKVB,
            Inregelen: hours.IBS * rates.IBS,
            Tekenwerk: hours.TEK * rates.TEK,
            WerkzaamhedenXconnect: hours.XCONNECT * rates.XCONNECT,
        })
        if (service) setPrices({
            ...prices,
            EngineerProjectBegeleiding: hours.ENG * ratesSell.ENG + hours.PL * ratesSell.PL + hours.WERKVB * ratesSell.WERKVB,
            Inregelen: hours.IBS * ratesSell.IBS,
            Tekenwerk: hours.TEK * ratesSell.TEK,
            WerkzaamhedenXconnect: hours.XCONNECT * ratesSell.XCONNECT,
        })
    }, [hours, service])

    // Save the form in the way the backend expects
    const save = async () => {
        setSaveLoading(true)
        const data = {
            "project_form_name": projectInfo.Projectnaam,
            "project_form_code": projectInfo.Projectnummer,
            "project_form_country": projectInfo.Postcode,
            "project_form_field_equipment_cost_price": costs.Regelapparatuur,
            "project_form_field_equipment_list_price": prices.Regelapparatuur,
            "project_form_field_equipment_discount": discount,
            "project_form_network_equipment_cost_price": costs.NetwerkControllers,
            "project_form_network_equipment_list_price": prices.NetwerkControllers,
            "project_form_netto_equipment_cost_price": costs.NettoApparatuur,
            "project_form_netto_equipment_list_price": prices.NettoApparatuur,
            "project_form_eng_pl_list_price": prices.EngineerProjectBegeleiding,
            "project_form_ibs_list_price": prices.Inregelen,
            "project_form_eb_cost_price": costs.Schakelkasten,
            "project_form_eb_list_price": prices.Schakelkasten,
            "project_form_installation_cost_price": costs.Installatie,
            "project_form_installation_list_price": prices.Installatie,
            "project_form_open_category": "Netto verkoopprijs / Diversen",
            "project_form_open_category_cost_price": costs.NettoverkoopprijsDiversen,
            "project_form_open_category_list_price": prices.NettoverkoopprijsDiversen,
            "project_form_cabling_cost_price": costs.Bekabeling,
            "project_form_cabling_list_price": prices.Bekabeling,
            "project_form_risc_percentage": risk || 0,
            "project_form_sales_name": projectInfo.Verkoper,
            "project_form_service_project": projectInfo.Gebouwnaam,
            "project_form_service_project_city": projectInfo.Plaatsnaam,
            "project_form_project_manager": projectInfo.Projectleider,
            "project_form_term_of_payment": projectInfo.Betalingstermijn,
            "project_form_vat": projectInfo.BTW,
            "project_form_assignment_date": projectInfo.Opdrachtdatum || null,
            "project_form_assignment_client": projectInfo.Opdrachtgever,
            "project_form_assignment_client_department": projectInfo.OpdrachtgeverAfdeling,
            "project_form_assignment_order_nr": projectInfo.Ordernummerklant,
            "project_form_address": projectInfo.Adres,
            "project_form_contact_name": projectInfo.Contactpersoon,
            "project_form_contact_email": projectInfo.email,
            "project_form_amount_eb_sm": projectInfo.AantalHangkasten,
            "project_form_amount_eb_lg": projectInfo.AantalStaandekasten,
            "project_form_service_assignment": service,
            "project_form_tek_hours": hours.TEK,
            "project_form_eng_hours": hours.ENG,
            "project_form_pl_hours": hours.PL,
            "project_form_werkvb_hours": hours.WERKVB,
            "project_form_ibs_hours": hours.IBS,
            "project_form_subc_hours": hours.XCONNECT,
            "project_form_nar_equipment_cost_price": costs.Naregelingen,
            "project_form_nar_equipment_list_price": prices.Naregelingen,
            "project_form_tek_list_price": prices.Tekenwerk,
            "project_form_subcontractor_list_price": prices.WerkzaamhedenXconnect,
        }

        const res = await projectApi.putProject(project.project_form_id, data)
        if (!res.ok) return setSaveLoading(false)
        setSaveLoading(false)
        const parsedList = facturatieList.map(f => {
            return {
                project_form_billing_order: f.key,
                project_form_billing_term_percentage: parseInt(f.basisP) || null,
                project_form_billing_text: f.x || null,
                project_form_billing_billed_percentage: parseInt(f.factedP) || null,
                project_form_billing_to_invoice_percentage: parseInt(f.factP) || null,
                project_form_billing_date: f.date || null,
                project_form_billing_description: f.description || null
            }

        })
        const resT = await projectApi.postFacturatie(project.project_form_id, parsedList)
        if (!resT.ok) return notyfApi.error("Kon de termijnen niet opslaan")
        notyfApi.success("Opgeslagen")

    }

    // When the NAW account is selected update the rest of the form
    const handleAccountChange = async (e) => {

        const resAccountDetails = await projectApi.getOfferAccountDetails(e.sales_account_id)
        if (!resAccountDetails.ok) return notyfApi.error("Kan aanvrager niet ophalen")
        setProjectInfo({
            ...projectInfo,
            Opdrachtgever: resAccountDetails.data.account.sales_account_name,
            Adres: `${resAccountDetails.data.account.sales_account_street || ""} ${resAccountDetails.data.account.sales_account_street_nr || ""}`,
            Postcode: resAccountDetails.data.account.sales_account_postal_code,
            Contactpersoon: `${e.sales_contact_firstname || ""} ${e.sales_contact_surname_prefix ? e.sales_contact_surname_prefix + " " : ""}${e.sales_contact_lastname || ""}`,
            email: e.sales_contact_email,
        })
    }
    const [print, setPrint] = useState(false)
    if (loading) return <Loader/>
    return (

        <div className='main-container p-4'>
            <div className={"container-fluid"}>
                <Button className={"position-fixed stuck"} size={'lg'} disabled={saveLoading} onClick={save}>{saveLoading ? <Spinner animation="border"/> : "Opslaan"}</Button>
                <Card>
                    <CardHeader className={"d-flex justify-content-between"}>

                        <h3>
                            Prijs Overzicht
                        </h3>
                        <EditFinanceModal sums={sums} project={project}/>
                    </CardHeader>
                    <CardBody>
                        <Row className={"mb-2 d-flex align-items-center"}>
                            <Col lg={"auto"}>Service opdracht</Col>
                            <Col lg={"auto"}>
                                <Checkbox className='customCheckBoxStyle' checked={service} onChange={() => setService(!service)}/>
                            </Col>
                            <Col lg={"auto"}>Bruto verkoopprijs</Col>
                            <Col lg={1}>
                                <InputFormat className={`infix ${!prices.Regelapparatuur && discount ? "invalid" : ""}`}
                                             value={prices.Regelapparatuur}
                                             onChange={e => setPrices({...prices, Regelapparatuur: Math.round(e) || null})}/>
                            </Col>
                            <Col lg={"auto"}>Korting</Col>
                            <Col lg={1}>
                                <NumberFormat className={"infix"}
                                              value={discount}
                                              onValueChange={e => setDiscount(e.floatValue)}
                                              displayType={'number'}
                                              suffix={'%'}/>

                            </Col>
                            <Col lg={"auto"}>Risico</Col>
                            <Col lg={1}>
                                <NumberFormat className={"infix"}
                                              value={risk}
                                              onValueChange={e => setRisk(e.floatValue)}
                                              displayType={'number'}
                                              suffix={'%'}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Table striped bordered>
                                    <thead>
                                    <tr>
                                        <th>Specificatie</th>
                                        <th style={{width: 150}}>Kost</th>
                                        <th style={{width: 150}}>Verkoop</th>
                                        <th style={{width: 100}}>Marge</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Regelapparatuur</td>
                                        <td>
                                            <InputFormat value={costs.Regelapparatuur}
                                                         onChange={e => setCosts({...costs, Regelapparatuur: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            {euroFormat(prices.Regelapparatuur * (1 - (discount / 100)) || null)}
                                        </td>
                                        <td>{margins.Regelapparatuur && `${(100 * margins.Regelapparatuur).toFixed(2)} %`}</td>
                                    </tr>
                                    <tr>
                                        <td>Netwerk Controllers</td>
                                        <td>
                                            <InputFormat value={costs.NetwerkControllers}
                                                         onChange={e => setCosts({...costs, NetwerkControllers: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.NetwerkControllers}
                                                         onChange={e => setPrices({...prices, NetwerkControllers: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.NetwerkControllers && `${(100 * margins.NetwerkControllers).toFixed(2)} %`}</td>
                                    </tr>
                                    <tr>
                                        <td>Naregelingen</td>
                                        <td>
                                            <InputFormat value={costs.Naregelingen}
                                                         onChange={e => setCosts({...costs, Naregelingen: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.Naregelingen}
                                                         onChange={e => setPrices({...prices, Naregelingen: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.Naregelingen && `${(100 * margins.Naregelingen).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Netto Apparatuur</td>
                                        <td>
                                            <InputFormat value={costs.NettoApparatuur}
                                                         onChange={e => setCosts({...costs, NettoApparatuur: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.NettoApparatuur}
                                                         onChange={e => setPrices({...prices, NettoApparatuur: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.NettoApparatuur && `${(100 * margins.NettoApparatuur).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Engineer / ProjectBegeleiding</td>
                                        <td>{euroFormat(costs.EngineerProjectBegeleiding)}</td>
                                        <td>
                                            {service ? euroFormat(prices.EngineerProjectBegeleiding) : <InputFormat disabeled={service} value={prices.EngineerProjectBegeleiding}
                                                                                                                    onChange={e => setPrices({
                                                                                                                        ...prices,
                                                                                                                        EngineerProjectBegeleiding: Math.round(e) || null
                                                                                                                    })}/>}
                                        </td>
                                        <td>{margins.EngineerProjectBegeleiding && `${(100 * margins.EngineerProjectBegeleiding).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Inregelen</td>
                                        <td>{euroFormat(costs.Inregelen)}</td>
                                        <td>
                                            {service ? euroFormat(prices.Inregelen) : <InputFormat value={prices.Inregelen}
                                                                                                   onChange={e => setPrices({...prices, Inregelen: Math.round(e) || null})}/>}
                                        </td>
                                        <td>{margins.Inregelen && `${(100 * margins.Inregelen).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Schakelkasten</td>
                                        <td>
                                            <InputFormat value={costs.Schakelkasten}
                                                         onChange={e => setCosts({...costs, Schakelkasten: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.Schakelkasten}
                                                         onChange={e => setPrices({...prices, Schakelkasten: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.Schakelkasten && `${(100 * margins.Schakelkasten).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Tekenwerk</td>
                                        <td>{euroFormat(costs.Tekenwerk)}</td>
                                        <td>
                                            {service ? euroFormat(prices.Tekenwerk) : <InputFormat value={prices.Tekenwerk}
                                                                                                   onChange={e => setPrices({...prices, Tekenwerk: Math.round(e) || null})}/>}
                                        </td>
                                        <td>{margins.Tekenwerk && `${(100 * margins.Tekenwerk).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Installatie</td>
                                        <td>
                                            <InputFormat value={costs.Installatie}
                                                         onChange={e => setCosts({...costs, Installatie: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.Installatie}
                                                         onChange={e => setPrices({...prices, Installatie: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.Installatie && `${(100 * margins.Installatie).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Werkzaamheden X-connect</td>
                                        <td>{euroFormat(costs.WerkzaamhedenXconnect)}</td>
                                        <td>
                                            {service ? euroFormat(prices.WerkzaamhedenXconnect) : <InputFormat value={prices.WerkzaamhedenXconnect}
                                                                                                               onChange={e => setPrices({
                                                                                                                   ...prices,
                                                                                                                   WerkzaamhedenXconnect: Math.round(e) || null
                                                                                                               })}/>}
                                        </td>
                                        <td>{margins.WerkzaamhedenXconnect && `${(100 * margins.WerkzaamhedenXconnect).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Netto verkoopprijs / Diversen</td>
                                        <td>
                                            <InputFormat value={costs.NettoverkoopprijsDiversen}
                                                         onChange={e => setCosts({...costs, NettoverkoopprijsDiversen: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.NettoverkoopprijsDiversen}
                                                         onChange={e => setPrices({...prices, NettoverkoopprijsDiversen: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.NettoverkoopprijsDiversen && `${(100 * margins.NettoverkoopprijsDiversen).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Bekabeling</td>
                                        <td>
                                            <InputFormat value={costs.Bekabeling}
                                                         onChange={e => setCosts({...costs, Bekabeling: Math.round(e) || null})}/>
                                        </td>
                                        <td>
                                            <InputFormat value={prices.Bekabeling}
                                                         onChange={e => setPrices({...prices, Bekabeling: Math.round(e) || null})}/>
                                        </td>
                                        <td>{margins.Bekabeling && `${(100 * margins.Bekabeling).toFixed(2)} %`}</td>

                                    </tr>
                                    <tr>
                                        <td>Risico {risk}%</td>
                                        <td>
                                            {euroFormat(sums.costs / (100 + risk) * 100 * (risk / 100))}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Totaalprijs exclusief B.T.W.</strong></td>
                                        <td>
                                            <strong>
                                                {euroFormat(sums.costs)}
                                            </strong>
                                        </td>
                                        <td>
                                            <strong>
                                                {euroFormat(sums.prices)}
                                            </strong>
                                        </td>
                                        <td>{(sums.costs && sums.prices) && `${((1 - (sums.costs / sums.prices)) * 100).toFixed(2)} %`}</td>

                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            {/*Arbeid tabel*/}
                            <Col>
                                <Row>
                                    <Table striped bordered>
                                        <thead>
                                        <tr>
                                            <th style={{width: 25}}></th>
                                            <th style={{width: 150}}>Arbeid</th>
                                            <th style={{width: 100}}>Uren</th>
                                            <th style={{width: 100}}>Dagen</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>TEK</td>
                                            <td>Tekenwerk</td>
                                            <td><Input className={"infix"} type={"number"} value={hours.TEK}
                                                       onChange={e => setHours({...hours, TEK: parseFloat(e.target.value) || null})}/>
                                            </td>
                                            <td>{(hours.TEK / 8).toFixed(1)}</td>
                                        </tr>
                                        <tr>
                                            <td>ENG</td>
                                            <td>Engineering</td>
                                            <td><Input className={"infix"} value={hours.ENG} onChange={e => setHours({...hours, ENG: parseFloat(e.target.value) || null})}/></td>
                                            <td>{(hours.ENG / 8).toFixed(1)}</td>
                                        </tr>
                                        <tr>
                                            <td>PL</td>
                                            <td>Projectbegeleiding</td>
                                            <td><Input className={"infix"} value={hours.PL} onChange={e => setHours({...hours, PL: parseFloat(e.target.value) || null})}/></td>
                                            <td>{(hours.PL / 8).toFixed(1)}</td>
                                        </tr>
                                        <tr>
                                            <td>WERKVB</td>
                                            <td>Werkvoorbereiding</td>

                                            <td><Input className={"infix"} value={hours.WERKVB} onChange={e => setHours({...hours, WERKVB: parseFloat(e.target.value) || null})}/>
                                            </td>
                                            <td>{(hours.WERKVB / 8).toFixed(1)}</td>
                                        </tr>
                                        <tr>
                                            <td>IBS</td>
                                            <td>In bedrijf stellen</td>
                                            <td><Input className={"infix"} value={hours.IBS} onChange={e => setHours({...hours, IBS: parseFloat(e.target.value) || null})}/></td>
                                            <td>{(hours.IBS / 8).toFixed(1)}</td>
                                        </tr>
                                        <tr>
                                            <td>XCONNECT</td>
                                            <td>Werkzaamheden X-connect</td>
                                            <td><Input className={"infix"} value={hours.XCONNECT}
                                                       onChange={e => setHours({...hours, XCONNECT: parseFloat(e.target.value) || null})}/></td>
                                            <td>{(hours.XCONNECT / 8).toFixed(1)}</td>
                                        </tr>
                                        </tbody>

                                    </Table>
                                </Row>
                                <Row>
                                    <Table striped bordered>
                                        <thead>
                                        <tr>
                                            <th style={{width: 25}}>BASISINSTELLINGEN</th>
                                            <th style={{width: 150}}>ENG/TEK/WKB</th>
                                            <th style={{width: 100}}>IBS</th>
                                            <th style={{width: 100}}>PL</th>
                                            <th style={{width: 100}}>X-CONNECT</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Uurtarief kost</td>
                                            <td>{euroFormat(rates.ENG)}</td>
                                            <td>{euroFormat(rates.IBS)}</td>
                                            <td>{euroFormat(rates.PL)}</td>
                                            <td>{euroFormat(rates.XCONNECT)}</td>
                                        </tr>
                                        <tr>
                                            <td>Uurtarief verkoop</td>

                                            <td>{euroFormat(ratesSell.ENG)}</td>
                                            <td>{euroFormat(ratesSell.IBS)}</td>
                                            <td>{euroFormat(ratesSell.PL)}</td>
                                            <td>{euroFormat(ratesSell.XCONNECT)}</td>
                                        </tr>

                                        </tbody>

                                    </Table>
                                </Row>

                            </Col>

                        </Row>

                    </CardBody>
                </Card>
                <Card className={"w-50"}>
                    <CardHeader className={"d-flex justify-content-between"}>
                        <h3>Project Gegevens</h3>
                    </CardHeader>
                    <CardBody>
                        <Table bordered striped>
                            <tbody>

                            <tr>
                                <td className={"w-50"}>Verkoper</td>
                                <td className={"w-50"}><Select
                                    options={sls_options}
                                    onChange={e => setProjectInfo({...projectInfo, Verkoper: e.value})}
                                    placeholder={<div>Selecteer een Verkoper</div>}
                                    defaultValue={{label: projectInfo.Verkoper, value: projectInfo.Verkoper}}

                                /></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Projectnaam</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer naam in"
                                                              value={projectInfo.Projectnaam}
                                                              onChange={e => setProjectInfo({...projectInfo, Projectnaam: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Projectnummer</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer nummer in"
                                                              value={projectInfo.Projectnummer}
                                                              onChange={e => setProjectInfo({...projectInfo, Projectnummer: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Gebouw naam</td>
                                <td className={"w-50"}>{projectInfo.Gebouwnaam}</td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Locatie</td>
                                <td className={"w-50 left-padding-4-life"}>{projectInfo.Plaatsnaam}</td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Projectleider</td>
                                <td className={"w-50"}><Select
                                    options={pms_options}
                                    onChange={e => setProjectInfo({...projectInfo, Projectleider: e.value})}
                                    placeholder={<div>Selecteer een Projectleider</div>}
                                    defaultValue={{label: projectInfo.Projectleider, value: projectInfo.Projectleider}}
                                /></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Betalingstermijn</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer nummer in"
                                                              value={projectInfo.Betalingstermijn}
                                                              onChange={e => setProjectInfo({...projectInfo, Betalingstermijn: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>BTW21% of BTWverlegd(0%)WKA</td>
                                <td className={"w-50"}><Select
                                    options={tax_options}
                                    onChange={e => setProjectInfo({...projectInfo, BTW: e.value})}
                                    placeholder={<div>Selecteer een Betalingstermijn</div>}
                                    defaultValue={{label: projectInfo.BTW, value: projectInfo.BTW}}
                                /></td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Totaal Orderbedrag</td>
                                <td className={"w-50 left-padding-4-life"}>{euroFormat(sums.prices)}</td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Opdrachtdatum</td>
                                <td className={"w-50"}><input className={"infix"} type={'date'} value={projectInfo.Opdrachtdatum}
                                                              onChange={e => setProjectInfo({...projectInfo, Opdrachtdatum: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Aantal Hangkasten</td>
                                <td className={"w-50"}><input className={"infix"} type={'number'} value={projectInfo.AantalHangkasten}
                                                              onChange={e => setProjectInfo({...projectInfo, AantalHangkasten: parseInt(e.target.value)})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Aantal Staandekasten</td>
                                <td className={"w-50"}><input className={"infix"} type={'number'} value={projectInfo.AantalStaandekasten}
                                                              onChange={e => setProjectInfo({...projectInfo, AantalStaandekasten: parseInt(e.target.value)})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Ordernummer klant</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer Ordernummer klant in" value={projectInfo.Ordernummerklant}
                                                              onChange={e => setProjectInfo({...projectInfo, Ordernummerklant: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Opdrachtgever</td>
                                <td className={"w-50"}>
                                    <Select
                                        options={acs_options}
                                        onChange={e => handleAccountChange(e.value)}
                                        placeholder={<div>Selecteer een aanvrager</div>}
                                        value={{label: `${projectInfo.Contactpersoon || ""} ${projectInfo.Opdrachtgever || ""}`, value: projectInfo.Opdrachtgever}}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td className={"w-50"}>Contactpersoon</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer Contactpersoon in" value={projectInfo.Contactpersoon}
                                                              onChange={e => setProjectInfo({...projectInfo, Contactpersoon: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Adres</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer Adres in" value={projectInfo.Adres}
                                                              onChange={e => setProjectInfo({...projectInfo, Adres: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Postcode</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer Postcode in" value={projectInfo.Postcode}
                                                              onChange={e => setProjectInfo({...projectInfo, Postcode: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>
                            <tr>
                                <td className={"w-50"}>Email Contactpersoon</td>
                                <td className={"w-50"}><input className={"infix"} type={'text'} placeholder="Voer Email in" value={projectInfo.email}
                                                              onChange={e => setProjectInfo({...projectInfo, email: e.target.value})}
                                                              style={{width: "100%", border: "1px solid #bbbbbb"}}/>
                                </td>
                            </tr>


                            </tbody>
                        </Table>
                    </CardBody>
                </Card>
                <Facturatie total={sums.prices > 250 ? sums.prices : 250} id={project.project_form_id} update={e => setFacturatieList(e)}/>
            </div>
            {print && <PDFDownloadLink style={{textDecoration: "inherit", color: "inherit"}} fileName={`Project formulier ${projectInfo.Projectnaam}`}
                                       document={<MyDocument project={{
                                           discount,
                                           risk,
                                           service,
                                           costs,
                                           prices,
                                           margins,
                                           sums,
                                           hours,
                                           rates,
                                           ratesSell,
                                           projectInfo,
                                           facturatieList,
                                       }}/>}>
                <Button size={'lg'} onClick={() => setTimeout(() => setPrint(false), 500)} style={{backgroundColor: "#8bc34b", borderColor: "#8bc34b"}}
                        className={'float-lg-right m-1'}>
                    Print
                </Button>
            </PDFDownloadLink>}
            {!print && <Button onClick={() => setPrint(true)} size={'lg'} className={'float-lg-right m-1'}>
                Laad print
            </Button>}

        </div>
    );

}


export default ProjectForm;
