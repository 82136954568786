import React, { useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Upload} from "react-feather";
import {Form as FormikForm, FormField, FormFieldDatePicker, SubmitButton} from "./forms";
import moment from "moment";
import notyfApi from "../utility/notyfApi";
import * as Yup from "yup";
import projectApi from "../api/project";

// This component sends the data to the portal using a form
function EditFinanceModal({sums ,project}) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleSubmit = async (values, {setSubmitting}) => {
        setSubmitting(false);
        const res = await projectApi.sendFinanceInfo(project.project_form_project_id, {
            ...values,
            sales_offer_expected_assignment_date: moment(values.sales_offer_expected_assignment_date).toISOString().slice(0, -1),
            sales_offer_expected_completion_date: moment(values.sales_offer_expected_completion_date).toISOString().slice(0, -1),
            sales_offer_margin: parseFloat(values.sales_offer_margin),
            sales_offer_scoring_chance: parseFloat(values.sales_offer_scoring_chance),
            sales_offer_quotation_amount: parseFloat(values.sales_offer_quotation_amount),
        })
        if (!res.ok) return notyfApi.error("Error " + res.status)
        notyfApi.success("Opgeslagen")
        handleClose()

    };

    return (
        <>
            <button className="Menu-extra float-end" onClick={handleShow}>
                <Upload className={"float-end cursor-pointer"}/>
            </button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Financieel aanpassen
                    </Modal.Title>
                </Modal.Header>
                <FormikForm
                    initialValues={{
                        sales_offer_margin: (sums.costs && sums.prices) ? ((1 - (sums.costs / sums.prices)) * 100).toFixed(2) : 0,
                        sales_offer_scoring_chance:  0,
                        sales_offer_quotation_amount:  sums ? sums.prices : 0,
                        sales_offer_expected_assignment_date:  "",
                        sales_offer_expected_completion_date:  "",
                    }}

                    validationSchema={Yup.object({
                        sales_offer_margin: Yup.number().required('Marge moet ingevuld worden'),
                        sales_offer_scoring_chance: Yup.number().required('Scoring kans moet ingevuld worden'),
                        sales_offer_quotation_amount: Yup.number().required('Offertebedrag moet ingevuld worden'),
                        sales_offer_expected_assignment_date: Yup.date().required("Verwachte opdrachtdatum moet ingevuld worden"),
                        sales_offer_expected_completion_date: Yup.date().required("Verwachte opleverdatum moet ingevuld worden"),
                    })}


                    onSubmit={handleSubmit}
                >
                    <Modal.Body>

                        <FormField name={"sales_offer_margin"}
                                   label={"Marge (%):*"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   control={"input"}
                                   type={"number"}
                                   disabled={true}

                        />
                        <FormField name={"sales_offer_scoring_chance"}
                                   label={"Scoringskans (%):*"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   type={"number"}

                        />
                        <FormField name={"sales_offer_quotation_amount"}
                                   label={"Offertebedrag (€):*"}
                                   width={"100%"}
                                   className={"mb-3"}
                                   type={"number"}
                                   disabled={true}

                        />
                        <FormFieldDatePicker name={"sales_offer_expected_assignment_date"}
                                             label={"Verwachte opdrachtdatum:*"}
                                             width={"100%"}
                                             className={"mb-3 form-control"}
                                             control={"input"}
                        />
                        <FormFieldDatePicker name={"sales_offer_expected_completion_date"}
                                             label={"Verwachte opleverdatum:*"}
                                             width={"100%"}
                                             className={"mb-3 form-control"}
                                             control={"input"}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Niet aanpassen
                        </Button>
                        <SubmitButton title={"Opslaan"} variant="primary"/>
                    </Modal.Footer>
                </FormikForm>
            </Modal>
        </>
    );
}

export default EditFinanceModal;
