import {Page, Text, View, Document, StyleSheet, Font} from '@react-pdf/renderer';
import {DataTableCell, Table, TableBody, TableCell, TableHeader,} from "@david.kucsai/react-pdf-table";
import moment from "moment";

// PDF generator
export const MyDocument = ({project}) => {

    return <Document>
        <Page style={styles.body}>
            <Text style={styles.title}>Project Formulier</Text>
            <View style={styles.table}>

                <Table
                    data={[
                        {
                            name: 'Project naam',
                            value: project.projectInfo.Projectnaam,
                        },
                        {
                            name: 'Projectnummer',
                            value: project.projectInfo.Projectnummer,
                        },
                        {
                            name: 'Verkoper',
                            value: project.projectInfo.Verkoper,
                        },
                        {
                            name: 'Gebouw naam',
                            value: project.projectInfo.Gebouwnaam,
                        },
                        {
                            name: 'Locatie',
                            value: project.projectInfo.Plaatsnaam,
                        },
                        {
                            name: 'Projectleider',
                            value: project.projectInfo.Projectleider,
                        },
                        {
                            name: 'Betalingstermijn',
                            value: project.projectInfo.Betalingstermijn,
                        },
                        {
                            name: 'BTW',
                            value: project.projectInfo.BTW,
                        },
                        {
                            name: 'Totaal Orderbedrag',
                            value: Intl.NumberFormat('nl-NL', {
                                style: 'currency',
                                currency: 'EUR',
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }).format(project.sums.prices),
                        },
                        {
                            name: 'Opdrachtdatum',
                            value: moment(project.projectInfo.Opdrachtdatum).format("DD/MM/YYYY"),
                        },
                        {
                            name: 'Ordernummer klant',
                            value: project.projectInfo.Ordernummerklant,
                        },
                        {
                            name: 'Opdrachtgever',
                            value: project.projectInfo.Opdrachtgever,
                        },
                        {
                            name: 'Adres',
                            value: project.projectInfo.Adres,
                        },
                        {
                            name: 'Contactpersoon',
                            value: project.projectInfo.Contactpersoon,
                        },
                        {
                            name: 'Email Contactpersoon',
                            value: project.projectInfo.email,
                        },
                        {
                            name: 'Aantal Hangkasten',
                            value: project.projectInfo.AantalHangkasten,
                        },
                        {
                            name: 'Aantal Staandekasten',
                            value: project.projectInfo.AantalStaandekasten,
                        },
                    ]}
                >
                    <TableHeader includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                                 fontSize={8} textAlign={"center"}>
                        <TableCell style={styles.headerLeft}>
                            ProjectInfo
                        </TableCell>
                    </TableHeader>
                    <TableBody includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                               includeBottomBorder={false} fontSize={8}>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.name}/>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.value}/>
                    </TableBody>
                </Table>
            </View>

            {/* Prijs Overzicht */}
            <View style={styles.table}>
                {

                    <Table
                        data={[
                            {
                                specification: "Regelapparatuur",
                                cost: project.costs.Regelapparatuur,
                                sell: project.prices.Regelapparatuur  * (1 - (project.discount / 100)),
                                margin: project.margins.Regelapparatuur,
                            },
                            {
                                specification: "Netwerk Controllers",
                                cost: project.costs.NetwerkControllers,
                                sell: project.prices.NetwerkControllers,
                                margin: project.margins.NetwerkControllers,
                            },
                            {
                                specification: "Naregelingen",
                                cost: project.costs.Naregelingen,
                                sell: project.prices.Naregelingen,
                                margin: project.margins.Naregelingen,
                            },
                            {
                                specification: "Netto Apparatuur",
                                cost: project.costs.NettoApparatuur,
                                sell: project.prices.NettoApparatuur,
                                margin: project.margins.NettoApparatuur,
                            },
                            {
                                specification: "Engineer / ProjectBegeleiding",
                                cost: project.costs.EngineerProjectBegeleiding,
                                sell: project.prices.EngineerProjectBegeleiding,
                                margin: project.margins.EngineerProjectBegeleiding,
                            },
                            {
                                specification: "Inregelen",
                                cost: project.costs.Inregelen,
                                sell: project.prices.Inregelen,
                                margin: project.margins.Inregelen,
                            },
                            {
                                specification: "Schakelkasten",
                                cost: project.costs.Schakelkasten,
                                sell: project.prices.Schakelkasten,
                                margin: project.margins.Schakelkasten,
                            },
                            {
                                specification: "Tekenwerk",
                                cost: project.costs.Tekenwerk,
                                sell: project.prices.Tekenwerk,
                                margin: project.margins.Tekenwerk,
                            },
                            {
                                specification: "Installatie",
                                cost: project.costs.Installatie,
                                sell: project.prices.Installatie,
                                margin: project.margins.Installatie,
                            },
                            {
                                specification: "Werkzaamheden X-connect",
                                cost: project.costs.WerkzaamhedenXconnect,
                                sell: project.prices.WerkzaamhedenXconnect,
                                margin: project.margins.WerkzaamhedenXconnect,
                            },
                            {
                                specification: "Netto verkoopprijs / Diversen",
                                cost: project.costs.NettoverkoopprijsDiversen,
                                sell: project.prices.NettoverkoopprijsDiversen,
                                margin: project.margins.NettoverkoopprijsDiversen,
                            },
                            {
                                specification: "Bekabeling",
                                cost: project.costs.Bekabeling,
                                sell: project.prices.Bekabeling,
                                margin: project.margins.Bekabeling,
                            },
                            {
                                specification: `Risico ${project.risk}%`,
                                cost: project.sums.costs / (100 + project.risk) * 100 * (project.risk / 100),
                            },
                            {
                                specification: "Totaalprijs exclusief B.T.W.",
                                cost: project.sums.costs,
                                sell: project.sums.prices,
                                margin: (project.sums.costs && project.sums.prices) && (1 - (project.sums.costs / project.sums.prices)),
                                bold: true,
                            },
                        ]}
                    >
                        <TableHeader includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                                     fontSize={8} textAlign={"center"}>
                            <TableCell style={styles.headerLeft}>
                                Sectie
                            </TableCell>
                            <TableCell style={styles.headerNumber}>
                                Kost
                            </TableCell>
                            <TableCell style={styles.headerNumber}>
                                Verkoop
                            </TableCell>
                            <TableCell style={styles.headerNumber}>
                                Marges
                            </TableCell>
                        </TableHeader>
                        <TableBody includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                                   includeBottomBorder={false} fontSize={8}>

                            <DataTableCell style={styles.cellText}
                                           getContent={(r) => r.bold ? <Text style={styles.cellTextBold}> {r.specification}</Text> : r.specification}/>
                            <DataTableCell style={styles.cellNumber}
                                           getContent={(r) => r.cost ?( r.bold ? <Text style={styles.cellTextBold}>{r.cost && Intl.NumberFormat('nl-NL', {
                                               style: 'currency',
                                               currency: 'EUR',
                                               minimumFractionDigits: 0,
                                               maximumFractionDigits: 0,
                                           }).format(r.cost)}</Text> : r.cost && Intl.NumberFormat('nl-NL', {
                                               style: 'currency',
                                               currency: 'EUR',
                                               minimumFractionDigits: 0,
                                               maximumFractionDigits: 0,
                                           }).format(r.cost)): ""}/>
                            <DataTableCell style={styles.cellNumber}
                                           getContent={(r) => r.sell ? ( r.bold ? <Text style={styles.cellTextBold}>{r.sell && Intl.NumberFormat('nl-NL', {
                                               style: 'currency',
                                               currency: 'EUR',
                                               minimumFractionDigits: 0,
                                               maximumFractionDigits: 0,
                                           }).format(r.sell)}</Text> : r.sell && Intl.NumberFormat('nl-NL', {
                                               style: 'currency',
                                               currency: 'EUR',
                                               minimumFractionDigits: 0,
                                               maximumFractionDigits: 0,
                                           }).format(r.sell)) :""}/>
                            <DataTableCell style={styles.cellNumber} getContent={(r) => r.bold ?
                                <Text style={styles.cellTextBold}>{r.margin && (r.margin * 100).toFixed(1) + '%'}</Text> : r.margin && (r.margin * 100).toFixed(1) + '%'}/>
                        </TableBody>
                    </Table>
                }
            </View>

            {/*Facturatie */}
            <View style={styles.table}>

                <Table
                    data={project.facturatieList}
                >
                    <TableHeader includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                                 fontSize={8} textAlign={"center"}>
                        <TableCell style={styles.headerNumber}>
                            Basis
                        </TableCell>
                        <TableCell style={styles.headerText}>

                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Gefactureerd
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Te Factureren
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Basis
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Gefactureerd
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Te Factureren
                        </TableCell>
                        <TableCell style={styles.headerText}>
                            Geplande Factuurdatum
                        </TableCell>
                        <TableCell style={styles.headerText}>
                            Toelichting
                        </TableCell>
                    </TableHeader>
                    <TableBody includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                               includeBottomBorder={false} fontSize={8}>
                        <DataTableCell style={styles.cellNumber} getContent={(r) => r.basisP ? r.basisP + '%': null}/>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.x}/>
                        <DataTableCell style={styles.cellNumber} getContent={(r) => r.factedP >= 0 && r.factedP + '%'}/>
                        <DataTableCell style={styles.cellNumber} getContent={(r) => r.factP >= 0 && r.factP + '%'}/>
                        <DataTableCell style={styles.cellNumber}
                                       getContent={(r) => r.basisP >= 0 && Intl.NumberFormat('nl-NL', {
                                           style: 'currency',
                                           currency: 'EUR',
                                           minimumFractionDigits: 0,
                                           maximumFractionDigits: 0,
                                       }).format(r.basisP / 100 * project.sums.prices)}/>
                        <DataTableCell style={styles.cellNumber}
                                       getContent={(r) => r.factedP >= 0 && Intl.NumberFormat('nl-NL', {
                                           style: 'currency',
                                           currency: 'EUR',
                                           minimumFractionDigits: 0,
                                           maximumFractionDigits: 0,
                                       }).format(r.factedP / 100 * project.sums.prices)}/>
                        <DataTableCell style={styles.cellNumber}
                                       getContent={(r) => r.factP >= 0 && Intl.NumberFormat('nl-NL', {
                                           style: 'currency',
                                           currency: 'EUR',
                                           minimumFractionDigits: 0,
                                           maximumFractionDigits: 0,
                                       }).format(r.factP / 100 * project.sums.prices)}/>

                        <DataTableCell style={styles.cellText} getContent={(r) => moment(r.date).format("DD/MM/YYYY")}/>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.description}/>
                    </TableBody>
                </Table>
            </View>
            <View style={styles.table}>
                <Table
                    data={[
                        {
                            name: 'Tekenwerk',
                            short: 'TEK',
                            hours: project.hours.TEK,
                            days: (parseInt(project.hours.TEK) / 8).toFixed(1),
                        },
                        {
                            name: 'Engineering',
                            short: 'ENG',
                            hours: project.hours.ENG,
                            days: (parseInt(project.hours.ENG) / 8).toFixed(1),
                        },
                        {
                            name: 'Projectleiding',
                            short: 'PL',
                            hours: project.hours.PL,
                            days: (parseInt(project.hours.PL) / 8).toFixed(1),
                        },
                        {
                            name: 'Werkvoorberijding',
                            short: 'WERKVB',
                            hours: project.hours.WERKVB,
                            days: (parseInt(project.hours.WERKVB) / 8).toFixed(1),
                        },
                        {
                            name: 'Inregelen',
                            short: 'IBS',
                            hours: project.hours.IBS,
                            days: (parseInt(project.hours.IBS) / 8).toFixed(1),
                        },
                        {
                            name: 'Werkzaamheden XCONNECT',
                            short: 'XCONNECT',
                            hours: project.hours.XCONNECT,
                            days: (parseInt(project.hours.XCONNECT) / 8).toFixed(1),
                        },
                        {
                            name: 'TOTAAL',
                            hours: (project.hours.TEK + project.hours.ENG + project.hours.PL + project.hours.WERKVB + project.hours.IBS + project.hours.XCONNECT).toFixed(0),
                            days: ((project.hours.TEK + project.hours.ENG + project.hours.PL + project.hours.WERKVB + project.hours.IBS + project.hours.XCONNECT) / 8).toFixed(1),
                            bold: true,

                        },
                    ]}
                >
                    <TableHeader includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                                 fontSize={8} textAlign={"center"}>
                        <TableCell style={styles.headerLeft}>
                        </TableCell>
                        <TableCell style={styles.headerText}>
                            Arbeid
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Uren
                        </TableCell>
                        <TableCell style={styles.headerNumber}>
                            Dagen
                        </TableCell>
                    </TableHeader>
                    <TableBody includeTopBorder={false} includeLeftBorder={false} includeRightBorder={false}
                               includeBottomBorder={false} fontSize={8}>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.bold ? <Text style={styles.cellTextBold}>{r.name}</Text> : r.name}/>
                        <DataTableCell style={styles.cellText} getContent={(r) => r.bold ? <Text style={styles.cellTextBold}>{r.short}</Text> : r.short}/>
                        <DataTableCell style={styles.cellNumber} getContent={(r) => r.bold ? <Text style={styles.cellTextBold}>{r.hours}</Text> : r.hours}/>
                        <DataTableCell style={styles.cellNumber} getContent={(r) => r.days !== "NaN" ? r.bold ? <Text style={styles.cellTextBold}>{r.days}</Text> : r.days: ""}/>
                    </TableBody>
                </Table>
            </View>


        </Page>
    </Document>
}

Font.registerHyphenationCallback(word => [word]);

const styles = StyleSheet.create({
    body: {
        paddingVertical: 35,
        paddingHorizontal: 35,
    },
    title: {
        fontSize: 16,
        textAlign: 'left',
        fontFamily: 'Helvetica',
        marginBottom: 15
    },
    table: {marginBottom: 15},

    headerNumber: {
        fontFamily: 'Helvetica-Bold',
        backgroundColor: '#eaeaea',
        paddingVertical: 2,

    },
    headerText: {
        fontFamily: 'Helvetica-Bold',
        padding: 2,
        backgroundColor: '#eaeaea',

    },
    headerLeft: {
        fontFamily: 'Helvetica-Bold',
        textAlign: 'left',
        padding: 2,
        backgroundColor: '#eaeaea',

    },
    cellNumber: {
        textAlign: 'center',
        fontFamily: 'Helvetica',
        paddingVertical: 2,


    },
    cellNumberAlignLeft: {
        textAlign: 'left',
        fontFamily: 'Helvetica',
        left: 10,
    },
    cellText: {
        fontFamily: 'Helvetica',
        padding: 2,
        justifyContent: 'start',
    },
    cellTextBold: {
        fontFamily: 'Helvetica-Bold',
    }, viewer: {
        width: "100%",
        height: '100%'
    },
});
