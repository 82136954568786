import React from "react";
import {Container} from "reactstrap";
import {Spinner} from "react-bootstrap";

// Loader component
const Loader = () => (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100 ">
            <Spinner
                style={{
                    color: "#8BC34B",

                }}
                animation="border"
            />
    </Container>
);

export default Loader;
