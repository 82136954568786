import React from "react";
import {useFormikContext} from "formik";
import {Button} from "react-bootstrap";


function SubmitButton({title, disabled = false}) {
    const {handleSubmit} = useFormikContext();

    return <Button onClick={handleSubmit} disabled={disabled}>{title}</Button>;
}

export default SubmitButton;
