import React from "react";
import {useFormikContext} from "formik";

import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import {localeDatapicker} from "../../constants";

function AppFormFieldDatePicker({name, display, label, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();


    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <DatePicker dateFormat={"dd-MM-yyyy"}
                        locale={localeDatapicker}
                        calendarStartDay={1}
                        onBlur={() => setFieldTouched(name)}
                        selected={values[name] ? moment(values[name]).toDate() : null}
                        onChange={(date) => setFieldValue(name, date)}
                        {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormFieldDatePicker;
