import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import {Auth0Provider} from "@auth0/auth0-react";
import {BrowserRouter} from "react-router-dom";




ReactDOM.render(
    <Auth0Provider domain={process.env.REACT_APP_AUTH0_DOMAIN}
                   clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                   redirectUri={window.location.origin}
                   audience={process.env.REACT_APP_AUTH0_AUDIENCE}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>

    </Auth0Provider>,
    document.getElementById("app")
);
