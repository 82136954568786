import {create} from "apisauce";
import {getCookie} from "../utility/cookies";

const apiClient = create({
    baseURL: process.env.REACT_APP_RUST_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
});

apiClient.addAsyncRequestTransform(async (request) => {
    const authToken = getCookie("ycon_auth0_token")
    if (!authToken) return;
    request.headers["authorization"] = "Bearer " + authToken;


});

export default apiClient;