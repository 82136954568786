import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import Logo from "../public/images/logo_v2.png"
// HTML code for the Navbar on the side
// It receives data as props from the layout compenent and displays them
const NavBar = ({project}) => {
    const {logout} = useAuth0();

    return (
        <div className="navbar navbar-expand-lg bg-dark bg-dark-blue navbar-dark sticky-top">
            <img src={Logo} style={{width:100, marginTop: -20}} alt={""}/>
            <div className="d-flex align-items-center">
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="d-block d-lg-none ml-2">
                    <div className="dropdown">
                        <div className="dropdown-menu dropdown-menu-right"></div>
                    </div>
                </div>
            </div>
            <div className="collapse navbar-collapse flex-column" id="navbar-collapse" style={{paddingTop: "15px"}}>
                <ul className="navbar-nav d-lg-block">
                    <p className="text-muted">{project.project_form_name}</p>
                    <p className="text-small text-muted">{project.project_form_code}</p>

                    <hr/>

                    <li className="nav-item">
                        <p className="nav-link">
                            Project Formulier
                        </p>
                    </li>
                </ul>
            </div>

            <ul className="nav nav-small flex-column">
                <li className="nav-item">
                    <p className="nav-link" onClick={logout}>Logout</p>
                </li>
            </ul>
        </div>
    );

};

export default NavBar;
