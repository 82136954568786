import React from "react";

// 404 page
const Page404 = () => (
  <div className="text-center">
    <h1 className="display-1 font-weight-bold">404</h1>
    <p className="h1">Pagina niet gevonden.</p>
    <p className="h2 font-weight-normal mt-3 mb-4">
        De pagina die je zocht bestaat niet.
    </p>
  </div>
);

export default Page404;
