import rustClient from "./rustClient";
import portalClient from "./portalClient"


const getProject = (id) => rustClient.get(`/forms/${id}`);
const putProject = (id, data) => rustClient.put(`/forms/${id}`, data);
const getFacturatie = (id) => rustClient.get(`/forms/${id}/terms`)
const postFacturatie = (id, data) => rustClient.post(`/forms/${id}/terms`, {terms: data})


const getEmployeesSales = () => portalClient.get("/users?role=Verkopers")
const getEmployeesProjects = () => portalClient.get("/users?role=Projectleider")
const getOfferInfo = (id) => portalClient.get(`/sales_offers/${id}`)
const getOfferAccounts = (id) => portalClient.get(`/sales_offers/${id}/accounts`)
const getOfferAccountDetails = (id) => portalClient.get(`/accounts/${id}`)


const sendFinanceInfo = (id, data) => portalClient.put(`/sales_offers/${id}/finance`, data);

const projectApi = {
    getProject,
    getFacturatie,
    getEmployeesSales,
    getEmployeesProjects,
    putProject,
    postFacturatie,
    getOfferInfo,
    getOfferAccounts,
    getOfferAccountDetails,
    sendFinanceInfo
};

export default projectApi