import React, {useEffect} from "react";
import {useFormikContext} from "formik";

import ErrorMessage from "./ErrorMessage";
import {Form} from "react-bootstrap";


function AppFormField({name, display, width, label, defaultValue, ...otherProps}) {
    const {setFieldTouched, setFieldValue, errors, touched, values} = useFormikContext();

    useEffect(() => {
        if (defaultValue) setFieldValue(name, defaultValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (display) {
        if (values[display.name] !== display.value) return <></>
    }

    return (
        <>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control

                onBlur={() => setFieldTouched(name)}
                onChange={text => setFieldValue(name, text.target.value)}
                value={values[name]}
                width={width}
                {...otherProps}
            />
            <ErrorMessage error={errors[name]} visible={touched[name]}/>
        </>
    );
}

export default AppFormField;
