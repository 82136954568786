import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";


import {getCookie, setCookie} from './utility/cookies';
import {useAuth0} from "@auth0/auth0-react";
import ProjectForm from "./components/ProjectForm";
import Page404 from "./components/Page404";
import projectApi from "./api/project";
import NavBar from "./components/layoutComponents/NavBar";
import Loader from "./components/Loader";
import axios from "axios";

// Interceptor which adds a cookie to every request
axios.interceptors.request.use(
    (request) => {
        if (request.url !== `${process.env.REACT_APP_PORTAL_URL}/auth/refresh_token`) {
            request.headers.authorization = "Bearer " + getCookie("ycon_auth0_token");

            return request
        } else {
            return request
        }

    },
    (error) => {
        return Promise.reject(error);
    }
);


const App = () => {
    const history = useHistory()
    const [project, setProject] = useState(false) // Context variables
    // Auth0
    const {user, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently} = useAuth0(); // Auth0 functions



    useEffect(() => {
        if (!isLoading) { // When loading has finished check authentication
            if (!isAuthenticated) redirectToLogin() // If not authenticated redirect to login page
            else checkAuth() // Procede with checking authentication
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isAuthenticated, isLoading])

    const redirectToLogin = async () => {
        if (history.location.pathname !== '/' && history.location.pathname !== '/logout') {
            localStorage.setItem('path', history.location.pathname) // Save the current path to the local storage

            history.push('/') // Send user to the root page for auth0
            await loginWithRedirect({redirectUri: history.location.origin})
        } else if (history.location.pathname === '/logout') history.push('/logout')
        else history.push('/404')
    }
    const checkAuth = async () => {
        const pathname = localStorage.getItem("path") // Get the saved path from localstorage
        if (pathname) {// If the path exist go to the page
            history.push(pathname)
            localStorage.removeItem("path")
        }
        const accessToken = await getAccessTokenSilently()
        setCookie('ycon_auth0_token', accessToken, 1 / 3) // Set the token retrieved from the auth0 hook
        loadProject() // Finally start the loading project

    }
    const loadProject = async () => {
        const res = await projectApi.getProject(history.location.pathname.slice(1)) // Get the parameter from the url and get the project
        if (!res.ok) (history.push("/404"))
        setProject(res.data) // Set the project
        document.title = res.data.project_form_code + " " + res.data.project_form_name // Set the title
    }
    if (history.location.pathname.includes("/404")) return <Page404/>

    if (project) return (

        <div className="layout layout-nav-side">
            <NavBar project={project}/>
            <ProjectForm project={project}/>
        </div>


    );
    return <Loader/>

}


export default App;