import React, {useEffect, useState} from 'react';
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {X} from "react-feather";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import projectApi from "../api/project";
import notyfApi from "../utility/notyfApi";

// This component handles facturation similar to the y-tool
function Facturatie({total, id, update}) {
    // State with default configuration
    const [tableList, setTableList] = useState([
        {
            key: 0,
            basisP: 100,
            x: 'bij opdracht',
            factedP: 0,
            factP: 0,
            basisE: () => 0,
            factedE: () => 0,
            factE: () => 0,
            date: '',
            description: ''
        }
    ]) // Default list

    // Here totals are calculated for the facturatie
    const [totals, setTotals] = useState({
        basisP: () => getTotals('basisP'),
        basisE: () => getTotals('basisE'),
        factedP: () => getTotals('factedP'),
        factedE: () => getTotals('factedE'),
        factP: () => getTotals('factP'),
        factE: () => getTotals('factE'),
    })

    // On load get the facturatie from the database and update the state
    useEffect(() => {
        const getTerms = async () => {
            const res = await projectApi.getFacturatie(id)
            if (!res.ok) return notyfApi.error("Kon de termijnen niet ophalen")
            let tempTableList = []
            if (res.data) {
                for (let row of res.data) {
                    tempTableList.push({
                            key: row.project_form_billing_order,
                            basisP: parseInt(row.project_form_billing_term_percentage) || 0,
                            x: row.project_form_billing_text,
                            factedP: parseInt(row.project_form_billing_billed_percentage) || 0,
                            factP: parseInt(row.project_form_billing_to_invoice_percentage) || 0,
                            basisE: () => 0,
                            factedE: () => 0,
                            factE: () => 0,
                            date: row.project_form_billing_date,
                            description: row.project_form_billing_description
                        }
                    )
                }
                setTableList(tempTableList)
                updateTable(tempTableList)
                update(tempTableList)

            }

        }
        getTerms()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Every time the tableList is updated, the totals are updated
    useEffect(() => {
        setTotals({
            basisP: () => getTotals('basisP'),
            basisE: () => getTotals('basisE'),
            factedP: () => getTotals('factedP'),
            factedE: () => getTotals('factedE'),
            factP: () => getTotals('factP'),
            factE: () => getTotals('factE'),
        })
        update(tableList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableList])

    // When the saveProp is true, the facturatie is saved to the database
    useEffect(() => {
        let newList = JSON.parse(JSON.stringify(tableList))

        for (let row of newList) {
            row.basisE = total * (row.basisP / 100);
            row.factedE = total * (row.factedP / 100);
            row.factE = total * (row.factP / 100);
        }
        setTableList(newList)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total])

    // Add a new row to the tableList
    const addRow = () => {
        let x = {
            key: tableList.length,
            basisP: 0,
            x: '',
            factedP: 0,
            factP: 0,
            basisE: 0,
            factedE: 0,
            factE: 0,
            date: '',
            description: ''
        }
        setTableList([...tableList, x])
    }

    // Remove a row from the tableList
    const deleteRow = (key) => {
        let newList = JSON.parse(JSON.stringify(tableList))
        newList = newList.filter(item => item.key !== key)
        setTableList(newList)

    }

    // Edit a row in the tableList
    const editTable = (edit, i, key) => {
        let editor = tableList[i]
        editor[key] = edit
        let newList = tableList.filter(item => item.key !== i)
        newList.push(editor)
        newList = newList.sort((a, b) => (a.key - b.key))


        setTableList(newList)
        setValues()
    }

    // Calculate the totals for the facturatie
    const getTotals = (key) => {
        let t = 0
        let newList = JSON.parse(JSON.stringify(tableList))
        for (let row of newList) {
            if (key === 'basisE' || key === 'factedE' || key === 'factE') {
                if (row[key]) {
                    t += (row[key])
                }

            } else {
                if (parseInt(row[key])) {
                    t += parseInt(row[key])
                }
            }
        }
        if (t) return t
        else return 0
    }


    // Save the facturatie to the database
    const setValues = () => {
        let newList = JSON.parse(JSON.stringify(tableList))

        for (let row of newList) {
            row.basisE = total * (row.basisP / 100);
            row.factedE = total * (row.factedP / 100);
            row.factE = total * (row.factP / 100);
        }
        setTableList(newList)

    }


     // Update the tableList in the state
    const updateTable = (tempTableList) => {
        let newList = tempTableList

        for (let row of newList) {
            row.basisE = total * (row.basisP / 100);
            row.factedE = total * (row.factedP / 100);
            row.factE = total * (row.factP / 100);
        }
        setTableList(newList)
    }


    return (<>
        <div className="card">
            <h3 className="card-header secondh3po2">Facturatie</h3>
            <div className="card-body">
                <FontAwesomeIcon icon={faPlus} onClick={addRow} className={"absol-icon Menu-extra"} style={{cursor: "pointer"}}/>
                <table className={'table table-bordered table-striped add-margin-bot'}>
                    <thead className="table-light">
                    <tr>
                        <th className={"w-10"}>Basis</th>
                        <th></th>
                        <th className={"w-auto"}>Gefactureerd</th>
                        <th className={"w-auto"}>Te Factureren</th>
                        <th className={"w-auto"}>Basis</th>
                        <th className={"w-auto"}>Gefactureerd</th>
                        <th className={"w-auto"}>Te Factureren</th>
                        <th className={"w-auto"}>Geplande Factuurdatum</th>
                        <th className={"w-auto"}>Toelichting</th>
                    </tr>
                    </thead>
                    <tbody className={"marginus-changimus"}>
                    {tableList.map(row => (<React.Fragment key={row.key}>
                            <tr>
                                <td><input style={{width: '50px'}} type="number" min={0} value={row.basisP} onChange={event => editTable(event.target.value, row.key, 'basisP')}/> %
                                </td>
                                <td><input style={{width: '200px'}} type="text" value={row.x} onChange={event => editTable(event.target.value, row.key, 'x')}/></td>
                                <td><input style={{width: '50px'}} type="number" min={0} value={row.factedP}
                                           onChange={event => editTable(event.target.value, row.key, 'factedP')}/> %
                                </td>
                                <td><input style={{width: '50px'}} type="number" min={0} value={row.factP} onChange={event => editTable(event.target.value, row.key, 'factP')}/> %
                                </td>
                                <td> {Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(row.basisE)}</td>
                                <td> {Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(row.factedE)}</td>
                                <td> {Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(row.factE)}</td>
                                <td><input style={{width: '150px'}} type="date" value={row.date} onChange={event => editTable(event.target.value, row.key, 'date')}/></td>
                                <td>
                                    <input style={{width: '180px'}} type="text" value={row.description} onChange={event => editTable(event.target.value, row.key, 'description')}/>
                                    <button className="Menu-extra" style={{float: "right"}} onClick={() => deleteRow(row.key)}>
                                        <X/>
                                    </button>

                                </td>

                            </tr>
                        </React.Fragment>
                    ))}

                    </tbody>
                    <tfoot className={"marginus-changimus"}>
                    <tr>
                        <td>{totals.basisP()} %</td>
                        <td></td>
                        <td>{totals.factedP()}</td>
                        <td>{totals.factP()}</td>
                        <td>{Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(totals.basisE())}</td>
                        <td>{Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(totals.factedE())}</td>
                        <td>{Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(totals.factE())}</td>
                        <td></td>
                        <td></td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        </div>

    </>);
}

export default Facturatie;